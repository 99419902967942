<template>
  <span class="flex items-center space-x-5 lg:space-x-4 w-full">
    <ImageComponent :src="user.image_full_url" rounded="rounded-full" class="w-10 h-10 lg:w-8 lg:h-8" />
    <h1 class=" text-sm font-light flex-shrink-0">
      {{ user.name }}
    </h1>
  </span>
</template>

<script>
import ImageComponent from "@/components/shared/ImageComponent.vue";

export default {
  name: "OrganizationTitle",

  props: {
    user: Object,
  },

  components: {
    ImageComponent,
  },
};
</script>
