<template>
  <img v-if="src" :src="src" :class="rounded" class="rounded">
  <div v-else :class="rounded" class=" bg-gray-500 rounded"></div>
</template>

<script>
export default {
  name: "ImageComponent",

  props: {
    src: String,
    rounded: {
      type: String,
      default: "rounded-full",
    },
    height: {
      type: String,
      default: "h-16",
    },
    width: {
      type: String,
      default: "w-16",
    },
  },
};
</script>
