<template>
  <div
    class="relative z-0 bg-white text-sm text-gray-900 flex items-center justify-between h-12 focus-within:shadow-outline rounded-full px-5 w-48 mr-2 cursor-pointer">
    <span class="flex-shrink-0">{{ type == 'new' ? 'New Events' : 'Processed Events' }}</span>
    <CarretDownIcon class="h-3 text-gray-500" />
    <select id="social_director_id" name="social_director_id" class="absolute inset-0 z-10 opacity-0 rounded-lg bg-customGray text-gray-900 w-full focus:outline-none focus:shadow-outline px-4 py-3" v-model="type">
      <option value="new">New Events</option>
      <option value="processed">Processed Events</option>
    </select>
  </div>
</template>

<script>
import CarretDownIcon from "@/components/svgs/CarretDownIcon.vue";

export default {
  name: "DropdownButton",

  props: {
    value: String,
  },

  components: {
    CarretDownIcon,
  },

  data() {
    return {
        type: 'new'
    }
  },

  watch: {
    type(type) {
        this.$emit('input', type)
    },

    value(value) {
        if (value === value) {
            return
        }

        this.type = value
    }
  },

  created() {
    this.type = this.value || 'new'
  }
};
</script>
