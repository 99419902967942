<template>
  <div class="flex items-center px-8 py-8">
    <div class="flex-1 illustration">
      <ErrorSvg />
    </div>
    <div class="flex-1">
      <h1 class="text-7xl font-normal mb-1 font-black">403</h1>
      <p class="text-3xl mb-6 font-semibold">Hold Up!</p>
      <p class="text-error-message mb-8 leading-normal text-lg">
        You don't seem to have access to this resource.
      </p>
      <button @click.prevent="$router.go(-1)" class="tracking-wide uppercase bg-green-500 h-12 px-8 rounded-lg text-white inline-flex items-center font-semibold focus:outline-none focus:shadow-outline hover:bg-green-600">
        Go Back
      </button>
    </div>
  </div>
</template>

<script>
  import ErrorSvg from '@/components/svgs/ErrorSvg'
  export default {
    components: {
      ErrorSvg,
    }
  }
</script>