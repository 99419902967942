<template>
  <div
    @click="toggleSelect"
    class="cursor-pointer flex items-center justify-between hover:bg-customGray rounded-3xl w-full px-8 lg:px-4"
    :class="{ 'bg-customGray': isSelected }">
    <UserComponent :user="user" class="py-4" />
    <CheckIcon v-if="isSelected" class="h-6 w-6 text-green-500" />
  </div>
</template>

<script>
  import UserComponent from "@/components/organization/UserComponent.vue";
  import CheckIcon from "@/components/svgs/CheckIcon.vue";
  export default {
    props: {
      user: Object,
      selected: {
        type: Array,
        default: () => [],
      }
    },

    components: {
      UserComponent,
      CheckIcon,
    },

    computed: {
      isSelected() {
        return this.selected.includes(this.user.id)
      }
    },

    methods: {
      toggleSelect() {
        this.$emit('toggle', this.user.id)
      }
    }
  }
</script>