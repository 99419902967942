<template>
  <div class="ml-0 mr-0 lg:mr-20  sm:p-0">
    <h1 class=" mb-6 font-medium">{{ event.attending_users.length || 'No' }} people going</h1>
    <div class="grid grid-cols-4 sm:grid-cols-10 md:grid-cols-6 lg:grid-cols-5 gap-x-2 md:gap-x-1 gap-y-3 md:gap-4 lg:gap-2 mb-10">
      <ImageComponent v-for="student in event.attending_users.slice(0, 10)" :key="student.id" :src="student.image_full_url" rounded="rounded-full"
        class="w-13 h-13 col-span-1" :title="student.name" />
    </div>
    <div class="flex items-center">
      <Modal>
        <button class="text-sm font-light flex items-center focus:outline-none focus:shadow-outline rounded-md p-1 -ml-1">
          <span>See Invite List</span>
          <BackIcon class="ml-3 w-8 text-customGreen transform rotate-180" />
        </button>

        <template v-slot:content="{ close }">
          <InviteListCard :users="event.invitations" @close="close" />
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/shared/Modal.vue";
import ImageComponent from "@/components/shared/ImageComponent.vue";
import BackIcon from "@/components/svgs/BackIcon.vue";
import InviteListCard from "@/components/events/EventDetails/partials/InviteListCard.vue";

export default {
  name: "GuestCard",
  
  props: {
    event: Object,
  },

  components: {
    ImageComponent,
    BackIcon,
    Modal,
    InviteListCard,
  },
};
</script>
