<template>
  <div v-if="organization" class="px-8 md:px-12">
    <!-- Header -->
    <router-link :to="{name: 'organizations.index'}">
      <BackButton />
    </router-link>
    <div class="grid grid-cols-12 gap-4 lg:gap-16">
      <div class="col-span-12 lg:col-span-5">
        <OrganizationTitle :organization="organization" textImage="w-16 h-16" textSize="text-2xl font-black" />
      </div>
      <div class="col-span-12 lg:col-span-5 flex items-center lg:justify-end">
        <div>
          <Modal v-if="organization.authorizedToAddMember">
            <button class="bg-green-500 rounded-lg text-white w-full hover:bg-green-600 text-xs w-24 px-5 flex items-center justify-center h-11 focus:outline-none focus:shadow-outline">
              <PlusIcon class="h-6" />
              <span class="ml-2">Add Member</span>
            </button>

            <template v-slot:content="{ close }">
              <AddMemberCard @close="closeAddMemberModal(close)" :organization="organization" />
            </template>
          </Modal>
        </div>
      </div>
    </div>
    <!-- Cards -->
    <div class="mt-8 grid grid-cols-12 gap-4 lg:gap-16 mb-12">
      <ApprovedCard :users="organization.users" :organization="organization" />
      <PendingCard :users="organization.pending_join_requests" :organization="organization" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import BackButton from "@/components/shared/BackButton.vue";
import OrganizationTitle from "@/components/organization/OrganizationTitle.vue";
import ApprovedCard from "@/components/organization/details/ApprovedCard/index.vue";
import PendingCard from "@/components/organization/details/PendingCard/index.vue";
import AddMemberCard from "@/components/organization/details/AddMemberCard.vue";
import PlusIcon from "@/components/svgs/PlusIcon.vue";
import Modal from "@/components/shared/Modal.vue";

export default {
  components: {
    BackButton,
    OrganizationTitle,
    ApprovedCard,
    PendingCard,
    PlusIcon,
    Modal,
    AddMemberCard,
  },

  computed: {
    organization() {
      return this.$store.state.organization
    }
  },

  methods: {
    ...mapActions({
      fetchOrganization: 'fetchOrganization',
    }),

    closeAddMemberModal(callback) {
      this.fetchOrganization(this.$route.params.organizationId)

      callback.call()
    }
  },

  created() {
    this.fetchOrganization(this.$route.params.organizationId)
  },

  beforeDestroy() {
    this.$store.commit('clearOrganization')
  }
};
</script>