<template>
  <svg width="48" height="19" viewBox="0 0 48 19" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M47.8299 0.391765C45.0838 -0.717511 41.9584 0.609402 40.8491 3.35551L38.9026 8.17418L38.9112 8.17736L37.3042 12.1677L34.413 4.31477H34.4016C34.2615 3.98805 34.0943 3.67522 33.8999 3.37628C33.2031 2.30421 32.2114 1.48229 30.925 0.910518C29.6385 0.338748 27.7266 0.0528633 25.9934 0.0528633H17.3633V18.8141H23.6885V14.2042H26.3955C28.1286 14.2042 29.6385 13.9183 30.925 13.3465C31.17 13.2376 31.4044 13.1196 31.6281 12.9925L34.0344 18.9753H40.306L46.2024 4.31477H46.1966L47.8299 0.391765ZM25.9934 9.32626C26.8511 9.32626 27.4943 9.13865 27.9232 8.76342C28.352 8.37033 28.5664 7.82536 28.5664 7.12852C28.5664 6.43167 28.352 5.89564 27.9232 5.52042C27.4943 5.12732 26.8511 4.93078 25.9934 4.93078H23.6885V9.32626H25.9934Z"
      fill="white" />
    <rect width="14.44" height="4.50154" rx="2.25077" fill="#00D900" />
    <rect y="14.4984" width="14.44" height="4.50154" rx="2.25077"
      fill="#00D900" />
    <rect x="4.73535" y="7.24927" width="9.70461" height="4.50154" rx="2.25077"
      fill="#00D900" />
  </svg>

</template>

<script>
export default {
  name: "Logo",
};
</script>
