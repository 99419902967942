<template>
  <div class="grid grid-cols-12 gap-y-2 gap-4 py-6 px-5 sm:px-10 overflow-hidden">
    <div
      class="flex justify-between lg:justify-start col-span-12 lg:col-span-5 mb-4 ml-0 lg:mb-0 space-x-2 sm:space-x-13">
      <div class="flex lg:flex-col items-center">
        <span class="mr-2 lg:mr-0 font-light lg:text-xs">
          {{ $dayjs(event.date).format('MMMM') }}
        </span>
        <h2 class="text-lg font-bold">
          {{ $dayjs(event.date).format('D') }}
        </h2>
      </div>
      <div class="flex items-center space-x-0 lg:space-x-5 w-7/12 lg:w-full">
        <div>
          <ImageComponent :src="event.logo_full_url" rounded="rounded-lg"
            class="w-12 h-12 lg:block hidden" />
        </div>
        <div class="flex w-full lg:flex-col justify-end">
          <h2 class="font-bold text-sm mr-5 lg:mr-0 flex-shrink-0">
            {{ event.name }}
          </h2>
          <span class=" flex-shrink-0 font-light text-sm lg:text-xs">
            {{ $dayjs(event.date).format('h:mm a') }}
          </span>
        </div>
      </div>
    </div>

    <div
      class="lg:col-span-7 col-span-12 flex items-center justify-between space-x-6">
      <div class="w-28">
        <EventStatus :status="eventStatus" />
      </div>
      
      <div class="flex items-center justify-end flex-1 space-x-2">
        <template v-if="! event.has_happened">
          <ActionModal
            v-if="canApprove"
            title="Approve Event"
            :subtitle="`Are you sure you want to approve this event?`"
            confirm-text="Approve Event"
            @confirm="approveEvent"
          >      
            <Button 
              title="Approve"
              class="hover:bg-green-500 w-20 lg:w-28 py-2 lg:py-3 text-sm" 
            />
          </ActionModal>
          
          <ActionModal
            v-if="canPutOnHold"
            :title="`Place ${event.name} On Hold`"
            :subtitle='`Please leave a comment about why you are placing this event on hold. If you do not choose to “Reject” this event by the scheduled date, the status will automatically be changed to "Approved".`'
            confirm-text="Place On Hold"
            color="warning"
            :disabled="reason.length < 15"
            @confirm="putOnHoldEvent"
            @cancel="reason = ''"
          >      
            <Button 
              colorButton="bg-customYellow" 
              title="On hold"
              class="hover:bg-yellow-600 w-16 lg:w-28 py-2 lg:py-3 text-sm" 
            />

            <template #content>
              <div class="mt-6">
                <textarea
                  class="resize-none bg-black w-full h-28 rounded-lg py-2 px-4 text-sm focus:outline-none focus:shadow-outline"
                  type="mensagge" 
                  placeholder="Comment..." 
                  v-model="reason"
                ></textarea>
                <p class="text-gray-600 text-xs text-right">min 15 chars</p>
              </div>
            </template>
          </ActionModal>

          <ActionModal
            v-if="event.authorizedToReject && eventStatus != 'rejected'" 
            :title="`Reject ${event.name}`"
            :subtitle="`Please leave a comment about why you rejected this event.`"
            confirm-text="Reject"
            :danger="true"
            :disabled="reason.length < 15"
            @confirm="rejectEvent"
            @cancel="reason = ''"
          >      
            <Button 
              colorButton="bg-customRed" 
              title="Reject"
              class="hover:bg-red-700 w-32 lg:w-28 py-2 lg:py-3 text-sm"
            />

            <template #content>
              <div class="mt-6">
                <textarea
                  class="resize-none bg-black w-full h-28 rounded-lg py-2 px-4 text-sm focus:outline-none focus:shadow-outline"
                  type="mensagge" 
                  placeholder="Comment..." 
                  v-model="reason" required
                ></textarea>
                <p class="text-gray-600 text-xs text-right">min 15 chars</p>
              </div>
            </template>
          </ActionModal>
        </template>
      </div>
      <div
        class="text-sm text-gray-500 font-light flex items-center">
        <router-link :to="{name: 'events.show', params: {eventId: event.id}}">Details</router-link>
        <DetailsArrowIcon class="lg:ml-3 ml-1" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { mapActions } from "vuex"
import Button from "@/components/shared/Button.vue"
import DetailsArrowIcon from "@/components/svgs/DetailsArrowIcon.vue"
import ImageComponent from "@/components/shared/ImageComponent.vue"
import ActionModal from "@/components/shared/ActionModal.vue"
import EventStatus from "@/components/events/EventStatus.vue"

export default {
  name: "EventRow",

  props: {
    event: Object,
  },

  components: {
    Button,
    DetailsArrowIcon,
    ImageComponent,
    EventStatus,
    ActionModal,
  },

  data() {
    return {
      reason: '',
    };
  },

  computed: {
    eventStatus() {
      if (this.event.is_processed) {
        return this.event.status.replace('_', ' ').toLowerCase()
      }

      if (this.event.status === 'ON_HOLD' && this.event.has_happened) {
        return 'rejected'
      }

      return 'pending'
    },

    canApprove() {
      return this.event.authorizedToApprove 
        && ['pending', 'on hold'].includes(this.eventStatus)
    },

    canPutOnHold() {
      return this.event.authorizedToPutOnHold 
        && ['pending', 'approved'].includes(this.eventStatus)
    },

    canReject() {
      return this.event.authorizedToReject 
        && this.eventStatus != 'rejected'
    },
  },

  methods: {
    ...mapActions({
      fetchEvents: 'fetchEvents',
    }),

    updateEventStatus(payload, callback) {
      axios.post(`/webapp/events/${this.event.id}/update-status`, payload)
        .then(() => {
          this.fetchEvents()
        })
        .catch(error => {
          if (error.response) {
            return this.$toast.error(error.response.data.message)
          }

          this.$toast.error('Server error');
        })
        .finally(() => {
          this.reason = ''
          callback.call()
        })
    },

    approveEvent(callback) {
      this.updateEventStatus({
        status: 'APPROVED',
        reason: '',
      }, callback)
    },

    putOnHoldEvent(callback) {
      this.updateEventStatus({
        status: 'ON_HOLD',
        reason: this.reason,
      }, callback)
    },

    rejectEvent(callback) {
      this.updateEventStatus({
        status: 'REJECTED',
        reason: this.reason,
      }, callback)
    }
  },
};
</script>
