<template>
  <div class="min-h-screen">
     <template v-if="! user">
      <div class="h-screen flex-1 flex items-center justify-center">
        <LoadingIcon class="h-3 text-customGreen" />
      </div>
    </template>

    <template v-else>
      <div class="bg-lightGray grid grid-cols-12 lg:grid-cols-12">
        <MobileNav />
        <SideNav />
        <div class="col-span-12 md:col-span-9 lg:col-span-10">
          <transition name="simple-fade" mode="out-in">
            <router-view />
          </transition>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import SideNav from "@/components/shared/SideNav";
  import MobileNav from "@/components/shared/MobileNav";
  import LoadingIcon from "@/components/svgs/LoadingIcon";
  export default {
    name: 'MainLayout',

    components: {
      SideNav,
      MobileNav,
      LoadingIcon,
    },

    computed: {
      user() {
        return this.$store.state.auth.user
      },
    },
    
    methods: {
      ...mapActions({
        fetchEventsCount: 'fetchEventsCount'
      })
    },

    created() {
      this.fetchEventsCount()
    }
  }
</script>