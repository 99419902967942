<template>
  <div class="flex items-center mb-4 md:mb-6 mt-10 px-2 md:mt-16">
    <BackIcon class="mr-2" />
    Back
  </div>
</template>

<script>
import BackIcon from "@/components/svgs/BackIcon.vue";
export default {
  name: "BackButton",
  components: {
    BackIcon,
  },
};
</script>
