<template>
  <div class="max-h-screen flex">
    <div class="w-full max-w-sm mx-auto bg-white rounded-3xl my-6 flex flex-col py-6 my-4">
      <div class="px-6 flex items-center justify-between">
        <h1 class="text-3xl font-black">Add Member</h1>
        <button @click.prevent="close" type="button" class="sm:hidden text-gray-500 rounded-lg focus:outline-none focus:shadow-outline">
          <XIcon class="h-10" />
        </button>
      </div>

      <div class="mt-6 px-6 w-full">
        <SearchComponent v-model="query" class="bg-customGray mb-8 w-full" />
        <VueFuse 
          class="hidden" 
          :search="query" 
          :fuse-opts="fuseOptions" 
          :map-results="true" 
          :list="users" 
          @fuse-results="filterUsers" 
        />
      </div>
      
      <div class="flex-1 flex flex-col w-full px-6 overflow-hidden">
        <template v-if="working">
          <div class="h-24 flex items-center justify-center">
            <LoadingIcon class="h-2 text-gray-500" />
          </div>
        </template>
        <template v-else-if="filteredUsers.length">
          <div class="flex-1 overflow-y-scroll">
            <StudentRow 
              v-for="user in filteredUsers" 
              :key="user.id" 
              :user="user" 
              @toggle="toggleSelected" 
              :selected="selectedUsers"
            />
          </div>
          <div class="flex-shrink-0 flex items-center justify-center pt-6">
            <button @click.prevent="addMembers" type="button" class="w-full text-white font-semibold rounded-lg bg-green-500 hover:bg-green-600 h-12 text-sm flex items-center justify-center disabled:opacity-50 focus:outline-none focus:shadow-outline" :disabled="! selectedUsers.length || adding">
              <span v-if="! adding">{{ selectedUsers.length == 1 ? 'Add Member' : 'Add Members' }}</span>
              <span v-else>
                <LoadingIcon class="h-2 text-white" />
              </span>
            </button>
          </div>
        </template>
        <div v-else-if="! filteredUsers.length && query.length" class="flex flex-col justify-center items-center space-y-6">
          <NoSearchResultsSvg class="h-24" />

          <h2 class="leading-tight text-lg font-medium text-gray-600 flex items-center flex-col">
            <span>No students found.</span>
            <span class="mt-2">
              <button @click.prevent="query = ''" type="button" class="text-sm font-semibold text-green-500">Clear Search</button>
            </span>
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import NoSearchResultsSvg from "@/components/svgs/NoSearchResultsSvg.vue";
  import SearchComponent from "@/components/shared/SearchComponent.vue";
  import StudentRow from "@/components/organization/details/StudentRow.vue";
  import LoadingIcon from "@/components/svgs/LoadingIcon.vue";
  import XIcon from "@/components/svgs/XIcon.vue";
  import VueFuse from 'vue-fuse';
  export default {
    name: 'AddMemberCard',

    props: {
      organization: Object,
    },

    components: {
      NoSearchResultsSvg,
      SearchComponent,
      VueFuse,
      LoadingIcon,
      XIcon,
      StudentRow,
    },

    data() {
      return {
        working: true,
        adding: false,
        query: '',
        fuseOptions: {
          keys: ['name']
        },
        filteredUsers: [],
        users: [],
        selectedUsers: [],
      }
    },

    computed: {
      existingMembersIds() {
        return this.organization.users.map(user => user.id)
      }
    },

    methods: {
      filterUsers(users) {
        this.filteredUsers = users
      },

      fetchStudents() {
        axios.get('/webapp/students')
          .then(({ data }) => {
            this.users = data.students.filter(user => ! this.existingMembersIds.includes(user.id))
          })
          .catch(error => {
            if (error.response) {
              return this.$toast.error(error.response.data.message)
            }

            this.$toast.error('Error! Could not load students.')
          })
          .finally(() => this.working = false)
      },

      toggleSelected(id) {
        let index = this.selectedUsers.findIndex(userId => userId == id)

        if (index === -1) {
          return this.selectedUsers.push(id)
        }

        this.selectedUsers.splice(index, 1)
      },

      close() {
        this.$emit('close')
      },

      addMembers() {
        this.adding = true
        
        axios.post(`/webapp/organizations/${this.organization.id}/members`, {
          users: this.selectedUsers,
        }).then(() => {
          this.close()
        }).catch(error => {
          if (error.response) {
            return this.$toast.error(error.response.data.message)
          }

          this.$toast.error('Error! Members were not added')
        }).finally(() => this.adding = false)
      }
    },

    created() {
      this.fetchStudents()
    }
  }
</script>