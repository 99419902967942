<template>
  <div class="bg-white rounded-xl divide-y divide-gray-300 mt-6 sm:mt-10">
    <OrganizationRow :organization="organization" v-for="organization in organizations" :key="organization.id" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import OrganizationRow from "@/components/organization/OrganizationCard/partials/OrganizationRow.vue";

export default {
  name: "OrganizationCard",

  components: {
    OrganizationRow,
  },

  computed: {
    ...mapState({
      organizations: state => state.organizations,
    }),
  },
};
</script>
