<template>
  <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.44981 0.257546L7.59871 3.99524C7.99935 4.46969 7.66421 5.20581 7.03449 5.20581H0.736703C0.114972 5.20581 -0.228495 4.47801 0.172486 3.99524L3.32138 0.257546C3.61564 -0.0859036 4.15555 -0.0859036 4.44983 0.257546H4.44981Z" fill="#D4D4D4"/>
<path d="M3.32265 11.8358L0.173751 8.09814C-0.226886 7.62369 0.108256 6.88757 0.737968 6.88757L7.03576 6.88757C7.65749 6.88757 8.00096 7.61537 7.59997 8.09814L4.45108 11.8358C4.15683 12.1793 3.61691 12.1793 3.32263 11.8358L3.32265 11.8358Z" fill="#D4D4D4"/>
</svg>

</template>

<script>
export default {
name:'DateIcon'
}
</script>
