<template>
  <div class="max-h-screen flex">
    <div class="w-full max-w-sm mx-auto bg-white rounded-3xl my-6 flex flex-col py-6 my-4">
      <div class="px-6 flex items-center justify-between">
        <h1 class="text-3xl font-black">Invite List</h1>
        <button @click.prevent="close" type="button" class="sm:hidden text-gray-500 rounded-lg focus:outline-none focus:shadow-outline">
          <XIcon class="h-10" />
        </button>
      </div>

      <div class="mt-6 px-6 w-full">
        <SearchComponent v-model="query" class="bg-customGray mb-8 w-full" />
        <VueFuse 
          class="hidden" 
          :search="query" 
          :fuse-opts="fuseOptions" 
          :map-results="true" 
          :list="users" 
          @fuse-results="filterUsers" 
        />
      </div>
      
      <div class="flex-1 flex flex-col w-full px-6 overflow-hidden">
        <template v-if="filteredUsers.length">
          <ul class="flex-1 overflow-y-scroll">
            <li 
              v-for="user in filteredUsers" 
              :key="user.id"  
              class="cursor-pointer flex items-center justify-between hover:bg-customGray rounded-3xl w-full px-8 lg:px-4"
            >
              <UserComponent :user="user" class="py-4" />
              <InviteStatus :status="user.pivot.status" />
            </li>
          </ul>
        </template>
        <div v-else-if="! filteredUsers.length && query.length" class="flex flex-col justify-center items-center space-y-6">
          <NoSearchResultsSvg class="h-24" />

          <h2 class="leading-tight text-lg font-medium text-gray-600 flex items-center flex-col">
            <span>No students found.</span>
            <span class="mt-2">
              <button @click.prevent="query = ''" type="button" class="text-sm font-semibold text-green-500">Clear Search</button>
            </span>
          </h2>
        </div>
        <div v-else-if="! filteredUsers.length && ! query.length" class="flex flex-col justify-center items-center space-y-6">
          <div class="h-32 w-32 rounded-full bg-gray-300 flex items-center justify-center">
            <StudentSvg class="h-24" />
          </div>

          <h2 class="leading-tight text-lg font-medium text-gray-600 flex items-center flex-col">
            <span>No invites sent.</span>
          </h2>
        </div>

        <div class="flex-shrink-0 flex items-center justify-center pt-6">
          <button @click.prevent="close" type="button" class="w-full text-white font-semibold rounded-lg bg-green-500 hover:bg-green-600 h-12 text-sm flex items-center justify-center disabled:opacity-50 focus:outline-none focus:shadow-outline">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import NoSearchResultsSvg from "@/components/svgs/NoSearchResultsSvg.vue";
  import SearchComponent from "@/components/shared/SearchComponent.vue";
  import XIcon from "@/components/svgs/XIcon.vue";
  import VueFuse from 'vue-fuse';
  import StudentSvg from "@/components/svgs/StudentSvg.vue";
  import UserComponent from "@/components/organization/UserComponent.vue";
  import InviteStatus from "@/components/events/EventDetails/partials/InviteStatus.vue";

  export default {
    name: 'InviteListCard',

    props: {
      users: Array,
    },

    components: {
      NoSearchResultsSvg,
      SearchComponent,
      VueFuse,
      XIcon,
      StudentSvg,
      UserComponent,
      InviteStatus,
    },

    data() {
      return {
        query: '',
        fuseOptions: {
          keys: ['name']
        },
        filteredUsers: [],
      }
    },

    methods: {
      filterUsers(users) {
        this.filteredUsers = users
      },

      close() {
        this.$emit('close')
      },
    }
  }
</script>