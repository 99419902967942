<template>
  <div class="px-8 py-4 md:p-12 relative">
    <h1 v-if="newEvents.length" class="font-black text-3xl mb-5 md:mb-8">Events</h1>

    <template v-if="working">
      <div class="h-screen flex-1 flex items-center justify-center">
        <LoadingIcon class="h-2 text-customGreen" />
      </div>
    </template>

    <template v-else>
      <div
        class="flex flex-col space-y-2 md:space-y-0 md:flex-row justify-between mb-8 lg:mb-0">
        <div>
          <h1 v-if="! newEvents.length" class="font-black text-3xl">Events</h1>
          <template v-else>
            <DropdownButton v-model="eventsType" />
          </template>
        </div>
        <SearchComponent />
      </div>
      <!-- Title Header -->
      <div
        class="bg-white  grid-cols-12 gap-4 py-4 px-10 rounded-xl mt-10 mb-8 hidden lg:grid">
        <div class="col-span-5 space-x-16 text-sm text-gray-500 flex">
          <button class="flex items-center">
            <span class="mr-2">Date</span>
            <DateIcon />
          </button>
          <span>Event</span>
        </div>
        <div class="col-span-12 lg:col-span-7 text-sm text-gray-500 space-x-6 flex items-center">
          <div class="w-28">Event Status</div>
          <div class="flex-1">Actions</div>
        </div>
      </div>
      <!--  -->
      <div v-if="! eventList.length">
        empty state
      </div>
      <div v-else class="bg-white rounded-xl divide-y divide-gray-300">
        <EventRow @clicked="onClick" v-for="event in eventList" :key="event.id" :event="event" />
      </div>
      <!-- Modal -->
      <ModalComponent @cancelModal="onClick" v-show="modal"/>
    </template>
  </div>
</template>

<script>
import LoadingIcon from "@/components/svgs/LoadingIcon.vue";
import DropdownButton from "@/components/events/DropdownButton.vue";
import EventRow from "@/components/events/EventRow.vue";
import SearchComponent from "@/components/shared/SearchComponent.vue";
import DateIcon from "@/components/svgs/DateIcon.vue";
import ModalComponent from "@/components/events/ModalComponent.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "EventsIndex",

  components: {
    LoadingIcon,
    DropdownButton,
    SearchComponent,
    DateIcon,
    EventRow,
    ModalComponent,
  },

  data() {
    return {
      working: true,
      modal: false,
      eventsType: 'new',
      eventList: [],
    };
  },
  computed: {
    ...mapState({
      events: (state) => state.events,
    }),

    newEvents() {
      return this.events.filter(event => {
        return ! event.is_processed && ! event.has_happened
      })
    },

    processedEvents() {
      return this.events.filter(event => {
        return event.is_processed || event.has_happened
      })
    }
  },

  watch: {
    events() {
      this.filterEvents()

      if (! this.newEvents.length) {
        this.eventsType = 'processed'
      }
    },

    eventsType() {
      this.filterEvents()
    }
  },

  methods: {
    ...mapActions({
      fetchEvents: 'fetchEvents',
    }),

    onClick() {
      this.modal = !this.modal // someValue
    },

    filterEvents() {
      switch (this.eventsType) {
        case 'new':
          return this.eventList = this.newEvents
        case 'processed':
          return this.eventList = this.processedEvents
      }
    }
  },

  created() {
    this.fetchEvents().then(() => {
      this.working = false
    })
  },
};
</script>
