<template>
  <div @click="open = true">
    <slot />

    <portal to="modals">
      <div v-if="open" class="fixed inset-0 bg-black bg-opacity-50 z-40 flex items-center justify-center" @click="close">
        <div
          @click.stop
          class="z-50 relative bg-lightBlack text-white p-6 rounded-lg shadow-xl bg-white w-80 sm:w-96 mx-auto">
          <h1 class="font-bold">
            {{ title }}
          </h1>
          <p class="text-sm font-light mt-2">
            {{ subtitle }}
          </p>
          
          <slot name="content" />

          <div class="mt-6 flex justify-center space-x-2 w-full">
            <div class="w-6/12">
              <button 
                @click.prevent="cancel" 
                class="w-full h-12 text-sm rounded-lg text-white font-semibold bg-transparent border hover:bg-gray-700 focus:outline-none focus:shadow-outline" 
              >
                {{ cancelText }}
              </button>
            </div>
            <div class="w-6/12">
              <button 
                @click.prevent="confirm(close)" 
                :class="actionButtonClasses"
                class="w-full h-12 text-sm rounded-lg text-white font-semibold flex items-center justify-center disabled:opacity-75 focus:outline-none focus:shadow-outline" 
                :disabled="working || disabled"
              >
                <span v-if="! working">{{ confirmText }}</span>
                <span v-else>
                  <LoadingIcon class="h-2 text-white" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import LoadingIcon from '@/components/svgs/LoadingIcon'
export default {
  name: "ActionModal",

  props: {
    title: {
      type: String,
      default: 'Confirm Action',
    },

    subtitle: {
      type: String,
      default: 'Are you sure you want to run this action?',
    },

    confirmText: {
      type: String,
      default: 'Confirm',
    },

    cancelText: {
      type: String,
      default: 'Cancel',
    },

    color: {
      type: String,
      default: 'green',
    },

    danger: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    }
  },

  components: {
    LoadingIcon
  },

  data() {
    return {
      open: false,
      working: false,
    }
  },

  watch: {
    open(open) {
      if (open) {
        document.addEventListener('keyup', this.handleEscape)
      } else {
        this.working = false
        document.removeEventListener('keyup', this.handleEscape)
      }
    }
  },

  computed: {
    actionButtonClasses() {
      if (this.danger) {
        return 'bg-customRed hover:bg-red-800'
      }

      if (this.color === 'warning') {
        return 'bg-yellow-500 hover:bg-yellow-600'
      }

      return 'bg-green-500 hover:bg-green-600'
    }
  },

  methods: {
    handleEscape(e) {
      if (e.keyCode === 27) {
        this.close()
      }
    },

    close() {
      this.open = false
    },

    cancel() {
      this.$emit('cancel')
      this.close()
    },

    confirm(callback) {
      this.working = true
      this.$emit('confirm', callback)
    }
  },
};
</script>
