<template>
  <svg class="w-3 h-3" viewBox="0 0 17 17" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.66666 14.3333C11.3486 14.3333 14.3333 11.3486 14.3333 7.66666C14.3333 3.98476 11.3486 1 7.66666 1C3.98476 1 1 3.98476 1 7.66666C1 11.3486 3.98476 14.3333 7.66666 14.3333Z"
      stroke="black" stroke-width="1.66666" stroke-linecap="round"
      stroke-linejoin="round" />
    <path d="M16 15.9999L12.375 12.3749" stroke="black" stroke-width="1.66666"
      stroke-linecap="round" stroke-linejoin="round" />
  </svg>

</template>

<script>
export default {
    name:'SearchIcon'
};
</script>
