<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.99023 8H15.9902" stroke="#FBCF35" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.99023 16H15.9902" stroke="#FBCF35" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.9902 21C17.9608 21 21.9902 16.9706 21.9902 12C21.9902 7.02944 17.9608 3 12.9902 3C8.01967 3 3.99023 7.02944 3.99023 12C3.99023 16.9706 8.01967 21 12.9902 21Z" stroke="#FBCF35" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.9902 8V10C10.9902 10.315 11.1382 10.611 11.3902 10.8L12.9902 12L14.5902 10.8C14.8422 10.611 14.9902 10.315 14.9902 10V8" stroke="#FBCF35" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.9902 16V14C14.9902 13.685 14.8422 13.389 14.5902 13.2L12.9902 12L11.3902 13.2C11.1382 13.389 10.9902 13.685 10.9902 14V16" stroke="#FBCF35" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
name:'PendingIcon'
}
</script>
