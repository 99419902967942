<template>
  <div class="max-h-screen flex">
    <ValidationObserver v-slot="{ handleSubmit, invalid }" class="flex-1 flex">
      <form method="post" ref="form" @submit.prevent="handleSubmit(addOrganization)" class="flex-1 flex">
        <div class="w-full max-w-sm mx-auto bg-white rounded-3xl my-6 flex flex-col py-6 my-4">
          <div class="px-8 flex items-center justify-between">
            <h1 class="text-3xl font-black">Add Organization</h1>
            <button @click.prevent="close" type="button" class="sm:hidden text-gray-500 rounded-lg focus:outline-none focus:shadow-outline">
              <XIcon class="h-10" />
            </button>
          </div>

          <div class="mt-6 px-8 w-full flex-1 overflow-y-scroll pb-1">
            <ValidationProvider mode="aggressive" rules="required" vid="name" name="Organization Name" v-slot="{ errors }">
              <div class="form-group">
                <label for="name" class="block text-sm text-gray-500">Organization Name</label>
                <div class="mt-2">
                  <input ref="nameInput" id="name" name="name" type="text" class="rounded-lg bg-customGray text-gray-900 w-full focus:outline-none focus:shadow-outline px-4 py-3" v-model="form.name">
                </div>
                <p class="text-red-600 text-sm mt-2" v-if="errors.length">{{ errors[0] }}</p>
              </div>
            </ValidationProvider>

            <div class="form-group mt-6">
              <label for="image" class="block text-sm text-gray-500">Organization Image</label>
                <div v-show="loadedImage == null" class="mt-2 border-customGreen border-dashed border-2 rounded-lg h-40 focus-within:shadow-outline">
                  <label for="image" class="w-full h-full block flex flex-col items-center justify-center hover:bg-gray-100 cursor-pointer">
                    <ImageIcon class="h-20 text-gray-500" />
                    <p class="text-gray-500 text-sm">
                      <span class="text-green-500">Add file</span> or drag and drop
                    </p>
                    <p class="text-gray-500 text-xs">PNG, JPG, GIF up to 10MB</p>
                  </label>
                  <input ref="orgImage" @change="handleImage" type="file" id="image" name="image" accept="image/*" class="opacity-0" />
                </div>
                <div v-if="loadedImage" class="mt-2 relative group border-customGreen border-dashed border-2 rounded-lg overflow-hidden">
                  <img :src="loadedImage" class="w-full h-40 rounded-lg object-cover" />
                  <div class="opacity-0 group-hover:opacity-100 absolute bottom-0 inset-x-0 h-10 bg-black bg-opacity-50 items-center justify-center hover:bg-opacity-75 transition-all duration-150 ease-in-out focus-within:opacity-100">
                    <button @click.prevent="clearSelectedImage" type="button" class="w-full h-full text-xs font-semibold text-white focus:outline-none focus:shadow-outline">
                      Remove Image
                    </button>
                  </div>
                </div>
            </div>

            <ValidationProvider mode="aggressive" rules="required" vid="address" name="Organization Address" v-slot="{ errors }">
              <div class="form-group mt-6">
                <label for="address" class="block text-sm text-gray-500">Organization Address</label>
                <div class="mt-2">
                  <input type="text" id="address" name="address" class="rounded-lg bg-customGray text-gray-900 w-full focus:outline-none focus:shadow-outline px-4 py-3" v-model="form.address">
                </div>
                <p class="text-red-600 text-sm mt-2" v-if="errors.length">{{ errors[0] }}</p>
              </div>
            </ValidationProvider>

            <ValidationProvider mode="lazy" rules="required" vid="president_id" name="Organization President" v-slot="{ errors }">
              <div class="form-group mt-6">
                <label for="president_id" class="block text-sm text-gray-500">Organization President</label>
                <div class="mt-2">
                  <div class="relative z-0 bg-customGray text-sm text-gray-900 flex items-center justify-between h-12 rounded-lg px-4 focus-within:shadow-outline">
                    <template v-if="selectedPresident">
                      <UserComponent :user="selectedPresident" />
                    </template>
                    <template v-else>
                      <em class="text-gray-500">Select a president</em>
                    </template>

                    <CarretDownIcon class="h-3 text-gray-500" />

                    <select id="president_id" name="president_id" class="absolute inset-0 z-10 opacity-0 rounded-lg bg-customGray text-gray-900 w-full focus:outline-none focus:shadow-outline px-4 py-3" v-model="form.president_id">
                      <option value hidden disabled>Select President</option>
                      <option v-for="user in filteredPresidents" :value="user.id" :key="user.id">
                        {{ user.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <p class="text-red-600 text-sm mt-2" v-if="errors.length">{{ errors[0] }}</p>
              </div>
            </ValidationProvider>

            <ValidationProvider mode="lazy" rules="required" vid="social_director_id" name="Organization Social Director" v-slot="{ errors }">
              <div class="form-group mt-6">
                <label for="social_director_id" class="block text-sm text-gray-500">Organization Social Director</label>
                <div class="mt-2">
                  <div class="relative z-0 bg-customGray text-sm text-gray-900 flex items-center justify-between h-12 rounded-lg px-4 focus-within:shadow-outline">
                    <template v-if="selectedSocialDirector">
                      <UserComponent :user="selectedSocialDirector" />
                    </template>
                    <template v-else>
                      <em class="text-gray-500">Select a social director</em>
                    </template>

                    <CarretDownIcon class="h-3 text-gray-500" />

                    <select id="social_director_id" name="social_director_id" class="absolute inset-0 z-10 opacity-0 rounded-lg bg-customGray text-gray-900 w-full focus:outline-none focus:shadow-outline px-4 py-3" v-model="form.social_director_id">
                      <option value hidden disabled>Select Social Director</option>
                      <option v-for="user in filteredSocialDirectors" :value="user.id" :key="user.id">
                        {{ user.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <p class="text-red-600 text-sm mt-2" v-if="errors.length">{{ errors[0] }}</p>
              </div>
            </ValidationProvider>
          </div>

          <div class="flex-shrink-0 flex items-center justify-center pt-6 px-8">
            <button type="submit" class="w-full text-white font-semibold rounded-lg bg-green-500 hover:bg-green-600 h-12 text-sm flex items-center justify-center disabled:opacity-50 focus:outline-none focus:shadow-outline" :disabled="invalid || adding">
              <span v-if="! adding">Add Organization</span>
              <span v-else>
                <LoadingIcon class="h-2 text-white" />
              </span>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import axios from 'axios'
  import ImageIcon from '@/components/svgs/ImageIcon'
  import XIcon from '@/components/svgs/XIcon'
  import LoadingIcon from '@/components/svgs/LoadingIcon'
  import CarretDownIcon from '@/components/svgs/CarretDownIcon'
  import UserComponent from '@/components/organization/UserComponent'
  export default {
    components: {
      XIcon,
      LoadingIcon,
      ImageIcon,
      UserComponent,
      CarretDownIcon,
    },

    data() {
      return {
        form: {
          name: null,
          image: null,
          address: null,
          president_id: null,
          social_director_id: null,
        },
        loadedImage: null,
        users: [],
        adding: false,
      }
    },

    computed: {
      selectedPresident() {
        if (! this.form.president_id) {
          return
        }

        return this.users.find(user => user.id == this.form.president_id)
      },

      selectedSocialDirector() {
        if (! this.form.social_director_id) {
          return
        }

        return this.users.find(user => user.id == this.form.social_director_id)
      },

      filteredPresidents() {
        return this.users.filter(user => user.id != this.form.social_director_id)
      },

      filteredSocialDirectors() {
        return this.users.filter(user => user.id != this.form.president_id)
      }
    },

    methods: {
      close() {
        this.$emit('close')
      },

      fetchStudents() {
        axios.get('/webapp/students?filter=no-leaders')
          .then(({ data }) => {
            this.users = data.students
          })
          .catch(error => {
            if (error.response) {
              return this.$toast.error(error.response.data.message)
            }

            this.$toast.error('Error! Could not load students.')
          })
      },

      handleImage() {        
        let file = this.$refs.orgImage.files[0]

        if (! file) {
          return 
        }
      
        let fileReader = new FileReader()
        
        fileReader.onload = e => {
          this.loadedImage = e.target.result
        }
        
        fileReader.onerror = e => {
          console.error(e);
        }

        fileReader.readAsDataURL(file);
      },

      addOrganization() {
        this.adding = true
        let form = new FormData(this.$refs.form)

        axios.post('/webapp/organizations', form)
          .then(({ data }) => {
            this.$router.push({
              name: 'organizations.show', 
              params: {
                organizationId: data.organization.id
              }
            })
          })
          .catch(error => {
            if (error.response) {
              return this.$toast.error(error.response.data.message)
            }

            this.$toast.error('Error! Organization was not added')
          })
          .finally(() => this.adding = false)
      },

      clearSelectedImage() {
        this.loadedImage = null

        this.$refs.orgImage.type = 'text'
        this.$refs.orgImage.type = 'file'
      }
    },

    created() {
      this.fetchStudents()
    },

    mounted() {
      this.$nextTick(() => {
        this.$refs.nameInput.focus()
      })
    }
  }
</script>