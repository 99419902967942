<template>
  <div @click="open = true">
    <slot />

    <portal to="modals">
      <div v-if="open" class="fixed inset-0 bg-black bg-opacity-50 z-40 flex items-center justify-center" @click="close">
        <div class="absolute top-0 right-0 mt-4 mr-8 hidden sm:block">
          <button @click.prevent="close" type="button" class="text-white rounded-lg focus:outline-none focus:shadow-outline">
            <XIcon class="h-10 text-white" />
          </button>
        </div>
        <div @click.stop class="w-full">
          <slot name="content" v-bind:close="close" />
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
  import XIcon from '@/components/svgs/XIcon'
  export default {
    name: 'Modal',

    components: {
      XIcon
    },

    data() {
      return {
        open: false,
      }
    },

    watch: {
      open(open) {
        if (open) {
          document.addEventListener('keyup', this.handleEscape)
        } else {
          this.working = false
          document.removeEventListener('keyup', this.handleEscape)
        }
      }
    },

    methods: {
      handleEscape(e) {
        if (e.keyCode === 27) {
          this.close()
        }
      },

      close() {
        this.open = false
      },
    }
  }
</script>