<template>
  <div class="flex items-center col-span-6 lg:col-span-6 space-x-5 lg:space-x-5">
    <span>
      <ImageComponent :src="organization.logo_full_url" :class="textImage" rounded="rounded-full"
        />
    </span>
    <h1 :class="textSize" class="font-semibold flex-shrink-0">
      {{ organization.name }}
    </h1>
  </div>
</template>

<script>
import ImageComponent from "@/components/shared/ImageComponent.vue";

export default {
  name: "UserComponent",

  components: {
    ImageComponent,
  },
  props: {
    organization: Object,
    textSize: {
      type: String,
      default: "text-base",
    },
    textImage: {
      type: String,
      default: "w-10 h-10",
    },
  },
};
</script>
