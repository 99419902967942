<template>
  <div
    class="flex flex-col sm:flex-row items-start sm:items-center justify-between rounded-lg w-11/12 px-4 mb-4 sm:mb-0 sm:px-2"
    @mouseover="active = true" @mouseout="active = false">
    <div>
      <UserComponent :user="user" class="py-4" />
    </div>

    <div class="flex space-x-6 lg:space-x-4">
      <ActionModal
        v-if="organization.authorizedToAcceptMember"
        title="Accept Join Request"
        :subtitle="`Are you sure you want to accept the join request from ${user.name}?`"
        confirm-text="Accept Request"
        @confirm="acceptJoinRequest"
      >      
        <Button title="Accept" color-button="bg-green-500" class="hover:bg-green-600 h-8.5 text-sm px-8 focus:outline-none focus:shadow-outline" />
      </ActionModal>
      <ActionModal
        v-if="organization.authorizedToDeclineMember"
        title="Decline Join Request"
        :subtitle="`Are you sure you want to decline the join request from ${user.name}?`"
        confirm-text="Decline Request"
        @confirm="declineJoinRequest"
        :danger="true"
      >      
        <Button colorButton="bg-transparent" title="Decline"
        class="hover:bg-customRed hover:text-white text-customRed border border-customRed h-8.5 text-xs py-2 px-8 focus:outline-none focus:shadow-outline" />
      </ActionModal>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import UserComponent from "@/components/organization/UserComponent.vue";
import Button from "@/components/shared/Button.vue";
import ActionModal from "@/components/shared/ActionModal.vue";

export default {
  name: "PendingRow",

  props: {
    user: Object,
    organization: Object,
  },

  components: {
    UserComponent,
    Button,
    ActionModal,
  },

  methods: {
    ...mapActions({
      fetchOrganization: 'fetchOrganization',
    }),

    /**
     * Accept Join Request
     */
    acceptJoinRequest(callback) {
      axios.patch(`/webapp/organizations/${this.organization.id}/join-requests/${this.user.id}/accept`)
        .then(() => {
          this.fetchOrganization(this.$route.params.organizationId)
        })
        .catch(error => {
          if (error.response) {
            return this.$toast.error(error.response.data.message)
          }

          this.$toast.error('Server error');
        })
        .finally(() => callback.call())
    },
    /**
     * Decline Join Request
     */
    declineJoinRequest(callback) {
      axios.patch(`/webapp/organizations/${this.organization.id}/join-requests/${this.user.id}/decline`)
        .then(() => {
          this.fetchOrganization(this.$route.params.organizationId)
        })
        .catch(error => {
          if (error.response) {
            return this.$toast.error(error.response.data.message)
          }

          this.$toast.error('Server error');
        })
        .finally(() => callback.call())
    },
  }
};
</script>

<style>
</style>