<template>
 <svg width="20" height="35" viewBox="0 0 20 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.49967 12.2093L13.333 17.5L7.49967 22.7907" stroke="#959595" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
name:'DetailsArrowIcon'
}
</script>
