<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.125 12.005H15.3027" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.125 15.9966L17.1275 16.0016" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<rect x="2.99609" y="2.99622" width="18.0075" height="18.0075" rx="2" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.125 7.99337H17.1275" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.12225 7.74828C6.98426 7.74883 6.87278 7.86105 6.87315 7.99905C6.87352 8.13704 6.98559 8.24867 7.12359 8.24848C7.26158 8.2483 7.37336 8.13638 7.37336 7.99838C7.37363 7.93179 7.34723 7.86787 7.30005 7.82088C7.25287 7.77389 7.18884 7.74774 7.12225 7.74828" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.12225 11.7499C6.98426 11.7504 6.87278 11.8626 6.87315 12.0006C6.87352 12.1386 6.98559 12.2503 7.12359 12.2501C7.26158 12.2499 7.37336 12.138 7.37336 12C7.37363 11.9334 7.34723 11.8695 7.30005 11.8225C7.25287 11.7755 7.18884 11.7493 7.12225 11.7499" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.12225 15.7516C6.98426 15.7521 6.87278 15.8643 6.87315 16.0023C6.87352 16.1403 6.98559 16.252 7.12359 16.2518C7.26158 16.2516 7.37336 16.1397 7.37336 16.0017C7.37363 15.9351 7.34723 15.8712 7.30005 15.8242C7.25287 15.7772 7.18884 15.751 7.12225 15.7516" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
name:'ListIcon'
}
</script>

<style>

</style>