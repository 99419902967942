<template>
  <div class="bg-white rounded-lg relative">
    <DetailHeader :event="event" />
     <!-- information Cards -->
    <div class="px-8 lg:px-16 py-10 grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-12">
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6">
        <DetailsInfoCard :data="eventDate" title="Date" />
        <DetailsInfoCard :data="eventTime" title="Time" />
        <DetailsInfoCard :data="event.location.address" title="Location" />
        <DetailsInfoCard :data="eventCost" title="Cost" />

        <div class="sm:col-span-2">
          <div class="pt-6">
            <h1 class="font-bold mb-6">About the Event</h1>
            <div class="col-span-10 font-light text-sm mb-3">
              {{ event.description }}
            </div>
          </div>
        </div>
      </div>
      <!-- people inside the event -->
      <div>
        <GuestCard :event="event" />
      </div>
    </div>
  </div>
</template>

<script>
import DetailHeader from "@/components/events/EventDetails/partials/DetailHeader.vue";
import DetailsInfoCard from "@/components/events/EventDetails/partials/DetailsInfoCard.vue";
import GuestCard from "@/components/events/EventDetails/partials/GuestCard.vue";

export default {
  name: "EventDetails",

  props: {
    event: Object,
  },
 
  components: {
    DetailHeader,
    DetailsInfoCard,
    GuestCard,
  },

  computed: {
    eventDate() {
      return this.$dayjs(this.event.date).format('MMMM DD, YYYY')
    },

    eventTime() {
      return this.$dayjs(this.event.date).format('hh:MM a')
    },

    eventCost() {
      if (this.event.cost == '0.00') {
        return 'Free'
      }

      return `$${this.event.cost}`
    }
  },
};
</script>
