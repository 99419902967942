<template>
  <div @mouseover="showIcon = true" @mouseleave="showIcon = false"
    class="flex items-center justify-between hover:bg-customGray rounded-lg w-full lg:w-11/12">
    <UserComponent :user="user" class="px-8 lg:px-4 py-3" />

    <ActionModal
      v-if="organization.authorizedToRemoveMember"
      v-show="showIcon"
      title="Remove Member"
      :subtitle="`Are you sure you want to remove ${user.name} from this organization?`"
      confirm-text="Remove Member"
      @confirm="removeMember"
      :danger="true"
    >      
      <button type="button" class="rounded mr-4 w-9 h-9 p-1 focus:outline-none focus:ring focus:ring-red-500">
        <RemoveIcon />
      </button>
    </ActionModal>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'
import UserComponent from "@/components/organization/UserComponent.vue";
import RemoveIcon from "@/components/svgs/RemoveIcon.vue";
import ActionModal from "@/components/shared/ActionModal.vue";

export default {
  name: "ApprovedRow",

  props: {
    user: Object,
    organization: Object,
  },

  components: {
    UserComponent,
    RemoveIcon,
    ActionModal,
  },
  data() {
    return {
      showIcon: false,
    };
  },

  methods: {
    ...mapActions({
      fetchOrganization: 'fetchOrganization',
    }),
      
    /**
     * Remove Member
     */
    removeMember(callback) {
      axios.delete(`/webapp/organizations/${this.organization.id}/members/${this.user.id}`)
        .then(() => {
          this.fetchOrganization(this.$route.params.organizationId)
        })
        .catch(error => {
          if (error.response) {
            return this.$toast.error(error.response.data.message)
          }

          this.$toast.error('Server error');
        })
        .finally(() => callback.call())
    },
  }
};
</script>
