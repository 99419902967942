<template>
  <div class="bg-white flex items-center rounded-full py-3 px-5">
    <div>
      <SearchIcon class="mr-2" />
    </div>
    <input style="outline: none;"
      type="search" class="w-full mr-3 bg-transparent text-sm"
      placeholder="Search..." v-model="query" />
  </div>
</template>

<script>
import SearchIcon from "@/components/svgs/SearchIcon.vue";

export default {
  name: "SearchComponent",

  props: {
    value: String,
  },

  data() {
    return {
      query: this.value,
    }
  },

  watch: {
    query(query) {
      this.$emit('input', query)
    },

    value(value) {
      if (value != this.query) {
        this.query = value
      }
    }
  },

  components: {
    SearchIcon,
  },
};
</script>
