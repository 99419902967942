<template>
  <div
    class="grid grid-cols-12 gap-y-2 gap-4 py-6 px-6 md:px-10 overflow-hidden">
    <OrganizationTitle :organization="organization" />
    <div class="col-span-12 lg:col-span-6 flex items-center">
      <div class="flex justify-between items-center w-full">
        <UserComponent :user="organization.president" class=""/>
        <router-link 
          v-if="organization.authorizedToView"
          :to="{name: 'organizations.show', params: {organizationId: organization.id}}">
          <span
            class="block text-sm text-gray-500 font-light flex items-center justify-center">
            Details
            <DetailsArrowIcon class="ml-4" />
          </span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import DetailsArrowIcon from "@/components/svgs/DetailsArrowIcon.vue";
import UserComponent from "@/components/organization/UserComponent.vue";
import OrganizationTitle from "@/components/organization/OrganizationTitle.vue";

export default {
  name: "OrganizationRow",

  props: {
    organization: Object,
  },

  components: {
    DetailsArrowIcon,
    UserComponent,
    OrganizationTitle,
  },
};
</script>
