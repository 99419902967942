<template>
  <div
    class="flex flex-col lg:flex-row items-start lg:items-center justify-between bg-black rounded-t-xl py-6 px-8 lg:px-16">
    <div class="flex items-center text-white w-full sm:w-1/2">
      <div>
        <ImageComponent :src="event.logo_full_url" rounded="rounded-xl mr-2 lg:mr-6"
          class="lg:w-20 lg:h-20 w-14 h-14" />
      </div>
      <h1 class="font-black text-lg lg:text-2xl">
        {{ event.name }}
      </h1>
    </div>
    <div class="w-full sm:w-1/2 flex flex-col space-y-2 lg:space-y-0 lg:flex-row lg:items-center lg:space-x-2 mt-6 lg:mt-0">
      <div class="w-28 mx-auto lg:mr-6">
        <EventStatus :status="eventStatus" />
      </div>
      <template v-if="! event.has_happened">
        <ActionModal
          v-if="canApprove"
          title="Approve Event"
          :subtitle="`Are you sure you want to approve this event?`"
          confirm-text="Approve Event"
          @confirm="approveEvent"
        >      
          <Button 
            title="Approve"
            class="hover:bg-green-500 w-20 lg:w-28 py-2 lg:py-3 text-sm" 
          />
        </ActionModal>
        
        <ActionModal
          v-if="canPutOnHold"
          :title="`Place ${event.name} On Hold`"
          :subtitle='`Please leave a comment about why you are placing this event on hold. If you do not choose to “Reject” this event by the scheduled date, the status will automatically be changed to "Approved".`'
          confirm-text="Place On Hold"
          color="warning"
          :disabled="reason.length < 15"
          @confirm="putOnHoldEvent"
          @cancel="reason = ''"
        >      
          <Button 
            colorButton="bg-customYellow" 
            title="On hold"
            class="hover:bg-yellow-600 w-16 lg:w-28 py-2 lg:py-3 text-sm" 
          />

          <template #content>
            <div class="mt-6">
              <textarea
                class="resize-none bg-black w-full h-28 rounded-lg py-2 px-4 text-sm focus:outline-none focus:shadow-outline"
                type="mensagge" 
                placeholder="Comment..." 
                v-model="reason"
              ></textarea>
              <p class="text-gray-600 text-xs text-right">min 15 chars</p>
            </div>
          </template>
        </ActionModal>

        <ActionModal
          v-if="event.authorizedToReject && eventStatus != 'rejected'" 
          :title="`Reject ${event.name}`"
          :subtitle="`Please leave a comment about why you rejected this event.`"
          confirm-text="Reject"
          :danger="true"
          :disabled="reason.length < 15"
          @confirm="rejectEvent"
          @cancel="reason = ''"
        >      
          <Button 
            colorButton="bg-customRed" 
            title="Reject"
            class="hover:bg-red-700 w-32 lg:w-28 py-2 lg:py-3 text-sm"
          />

          <template #content>
            <div class="mt-6">
              <textarea
                class="resize-none bg-black w-full h-28 rounded-lg py-2 px-4 text-sm focus:outline-none focus:shadow-outline"
                type="mensagge" 
                placeholder="Comment..." 
                v-model="reason" required
              ></textarea>
              <p class="text-gray-600 text-xs text-right">min 15 chars</p>
            </div>
          </template>
        </ActionModal>
      </template>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import ActionModal from "@/components/shared/ActionModal.vue";
import Button from "@/components/shared/Button.vue";
import ImageComponent from "@/components/shared/ImageComponent.vue";
import EventStatus from "@/components/events/EventStatus.vue";

export default {
  name: "DetailHeader",

  props: {
    event: Object,
  },

  components: {
    Button,
    ImageComponent,
    EventStatus,
    ActionModal,
  },

  data() {
    return {
      reason: '',
    };
  },

  computed: {
    eventStatus() {
      if (this.event.is_processed) {
        return this.event.status.replace('_', ' ').toLowerCase()
      }

      if (this.event.status === 'ON_HOLD' && this.event.has_happened) {
        return 'rejected'
      }

      return 'pending'
    },

    canApprove() {
      return this.event.authorizedToApprove 
        && ['pending', 'on hold'].includes(this.eventStatus)
    },

    canPutOnHold() {
      return this.event.authorizedToPutOnHold 
        && ['pending', 'approved'].includes(this.eventStatus)
    },

    canReject() {
      return this.event.authorizedToReject 
        && this.eventStatus != 'rejected'
    },
  },

  methods: {
    ...mapActions({
      fetchEvent: 'fetchEvent',
    }),
    updateEventStatus(payload, callback) {
      axios.post(`/webapp/events/${this.event.id}/update-status`, payload)
        .then(() => {
          this.fetchEvent(this.event.id)
        })
        .catch(error => {
          if (error.response) {
            return this.$toast.error(error.response.data.message)
          }

          this.$toast.error('Application Error');
        })
        .finally(() => {
          this.reason = ''
          callback.call()
        })
    },

    approveEvent(callback) {
      this.updateEventStatus({
        status: 'APPROVED',
        reason: '',
      }, callback)
    },

    putOnHoldEvent(callback) {
      this.updateEventStatus({
        status: 'ON_HOLD',
        reason: this.reason,
      }, callback)
    },

    rejectEvent(callback) {
      this.updateEventStatus({
        status: 'REJECTED',
        reason: this.reason,
      }, callback)
    }
  },
};
</script>
