<template>
  <div
    class="bg-gray-50 w-full flex flex-col justify-center p-4 mr-4 text-xs rounded-3xl h-20">
    <span class="text-gray3 mb-1 font">{{title}}</span>
    <span class=" font-bold">{{data}}</span>
  </div>
</template>

<script>
export default {
  name: "DetailsInfoCard",

  props: {
    title: String,
    data: String,
  },
};
</script>
