<template>
  <div class="bg-white rounded-lg w-full pb-4 col-span-12 lg:col-span-6">
    <h1 class="font-bold mb-2 px-8 py-6">Pending Requests</h1>
    <div v-if="users.length" class="flex flex-col justify-center items-center">
      <PendingRow v-for="user in users" :key="user.id" :user="user" :organization="organization" />
    </div>
    <div v-else class="flex flex-col justify-center items-center space-y-6">
      <OkIcon class="text-gray-500"/>
      <h2 class="leading-tight text-lg font-medium text-gray-600 flex items-center flex-col">
        <span>There are no pending</span>
        <span>join requests!</span>
      </h2>
    </div>
  </div>
</template>

<script>
import PendingRow from "@/components/organization/details/PendingCard/partials/PendingRow.vue";
import OkIcon from "@/components/svgs/OkIcon.vue";

export default {
  name: "RequestCard",

  props: {
    users: Array,
    organization: Object,
  },

  components: {
    PendingRow,
    OkIcon,
  },
};
</script>
