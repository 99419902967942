<template>
  <span class="text-xs font-medium capitalize" :class="statusTextColor">
    {{ statusText }}
  </span>
</template>

<script>
  export default {
    name: 'InviteStatus',

    props: {
      status: String,
    },

    computed: {
      statusText() {
        return this.status.toLowerCase()
      },

      statusTextColor() {
        let color

        switch(this.status) {
          case 'GOING':
            color = 'text-customGreen'
            break
          case 'MAYBE':
            color = 'text-customYellow'
            break
          case 'DECLINED':
            color = 'text-customRed'
            break
          case 'PENDING':
          default:
            color = 'text-gray3'
            break
        }

        return color
      }
    }
  }
</script>