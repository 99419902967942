<template>
  <div class=" bg-white rounded-lg col-span-12 lg:col-span-4">
    <div class="px-8 py-6">
      <h1 class=" font-bold">Approved Members</h1>
      <span class=" text-sm font-light text-gray-500">{{ users.length }} members</span>
    </div>
    <div
      class="flex flex-col items-stretch lg:items-center justify-center pb-4">
      <div class="px-6 w-full">
        <SearchComponent v-model="query" class="bg-customGray mb-8 w-full" />
        <VueFuse 
          class="hidden" 
          :search="query"
          :fuse-opts="fuseOptions" 
          :map-results="true" 
          :list="users" 
          @fuse-results="filterUsers" 
        />
      </div>

      <template v-if="filteredUsers.length">
        <ApprovedRow v-for="user in filteredUsers" :key="user.id" :user="user" :organization="organization" />
      </template>
      <div v-if="! filteredUsers.length && query.length" class="flex flex-col justify-center items-center space-y-6">
        <NoSearchResultsSvg class="h-24" />

        <h2 class="leading-tight text-lg font-medium text-gray-600 flex items-center flex-col">
          <span>No students found.</span>
          <span class="mt-2">
            <button @click.prevent="query = ''" type="button" class="text-sm font-semibold text-green-500">Clear Search</button>
          </span>
        </h2>
      </div>
      <div v-else-if="! filteredUsers.length && ! query.length" class="flex flex-col justify-center items-center space-y-6">
        <div class="h-32 w-32 rounded-full bg-gray-300 flex items-center justify-center">
          <StudentSvg class="h-24" />
        </div>

        <h2 class="leading-tight text-lg font-medium text-gray-600 flex items-center flex-col">
          <span>No members added.</span>
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import NoSearchResultsSvg from "@/components/svgs/NoSearchResultsSvg.vue";
import StudentSvg from "@/components/svgs/StudentSvg.vue";
import SearchComponent from "@/components/shared/SearchComponent.vue";
import ApprovedRow from "@/components/organization/details/ApprovedCard/partials/ApprovedRow.vue";
import { mapState } from "vuex";
import VueFuse from 'vue-fuse'

export default {
  name: "ApprovedCard",

  props: {
    users: Array,
    organization: Object,
  },

  components: {
    SearchComponent,
    ApprovedRow,
    VueFuse,
    NoSearchResultsSvg,
    StudentSvg,
  },

  data() {
    return {
      query: '',
      active: false,
      fuseOptions: {
        keys: ['name'],
      },
      filteredUsers: [],
    };
  },

  computed: {
    ...mapState({
      events: (state) => state.events,
    }),
  },

  methods: {
    filterUsers(users) {
      this.filteredUsers = users
    }
  },

  mounted() {
    this.filteredUsers = this.users
  }
};
</script>
