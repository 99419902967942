<template>
  <div
    class="col-span-3 lg:col-span-2 min-h-screen bg-black rounded-r-xl flex-col justify-between  text-white h-full py-8 px-10 hidden md:flex">
    <div class="flex flex-col">
      <div class="flex flex-col items-center">
        <router-link to="/">
          <Logo class=" mb-10" />
        </router-link>
        <ImageComponent :src="user.image_full_url" rounded="rounded-full" class="w-16 h-16 mb-6" />
        <h2 class="text-center text-sm font-bold">{{ user.name }}</h2>
        <span v-if="user.current_university" class="text-center text-xs text-gray-500 font-light">
          {{ user.current_university.name }}
        </span>
      </div>

      <nav class="mt-20">
        <router-link :to="{name: 'events.index'}" class="flex items-center mb-6 relative" :active-class="'text-customGreen'">
          <ListIcon class="mr-2" />
          <span class="text-white text-xs font-light">Events</span>
          <div
            v-if="eventsCount"
            class="bg-customRed rounded-full w-4 h-4 text-2xs flex justify-center items-center absolute ml-20 mb-4">
            {{ eventsCount }}
          </div>
        </router-link>
        <router-link :to="{name: 'organizations.index'}" class="flex items-center" :active-class="'text-customGreen'">
          <UsersIcon class="mr-2" />
          <span class="text-white text-xs font-light">Organizations</span>
        </router-link>
      </nav>
    </div>

    <button @click="signoutAlert" class="flex items-center">
      <LogOutIcon class="mr-2" />
      <span class="text-white text-xs font-light">log out</span>
    </button>

  </div>
</template>

<script>
import Logo from "@/components/svgs/Logo.vue";
import ListIcon from "@/components/svgs/ListIcon.vue";
import UsersIcon from "@/components/svgs/UsersIcon.vue";
import LogOutIcon from "@/components/svgs/LogOutIcon.vue";
import ImageComponent from "@/components/shared/ImageComponent.vue";
import { mapActions, mapState } from "vuex";
import { SIGN_OUT } from "@/store/types/actions";

export default {
  name: "SideNav",

  components: {
    Logo,
    ListIcon,
    UsersIcon,
    LogOutIcon,
    ImageComponent,
  },

  computed: {
    ...mapState({
      eventsCount: state => state.eventsCount,
      user: state => state.auth.user,
    }),
  },

  methods: {
    ...mapActions({
      signOut: `auth/${SIGN_OUT}`,
    }),

    signoutAlert() {
      this.signOut().then(() => {
        this.$router.push({ name: "signIn" });
      });
    },

    routeIs(name, exact) {
      if (exact) {
        return this.$route.fullPath == name
      }

      return this.$route.fullPath.includes(name)
    }
  },
};
</script>
