<template>
  <div
    class="bg-lightBlack text-white p-6 rounded-lg fixed inset-x-0 shadow-xl bg-white w-80 sm:w-96 mx-auto top-0 mt-10 md:mt-32 lg:mt-50">
    <h1 class="mb-2">Reject BBQ Hangout</h1>
    <p class=" text-xs font-light mb-6">Do you want to leave a comment about why
      you rejected this event?</p>
    <textarea
      class="resize-none bg-black w-full h-28 rounded-lg mb-6 py-2 px-4 text-sm"
      type="mensagge" placeholder="Comment..."></textarea>
    <div class="flex justify-center space-x-2 w-full px-2">
      <button @click.prevent="cancel" class="w-6/12 ">
        <Button colorButton="bg-transparent border"
          class="hover:bg-gray-700 w-full py-2 text-xs" title="Cancel" />
      </button>
      <button class="w-6/12">
        <Button colorButton="bg-customRed "
          class="hover:bg-red-800 w-full py-2 text-xs" title="Reject" />
      </button>

    </div>
  </div>
</template>

<script>
import Button from "@/components/shared/Button.vue";

export default {
  name: "ModalComponent",

  components: {
    Button,
  },
  methods: {
    cancel() {
      this.$emit("cancelModal");
    },
  },
};
</script>
