<template>
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.24805 10.2489H17.752" stroke="#E53E3E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.498 7.58175H14.4993" stroke="#E53E3E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.36543 10.2489V17.4429C9.35317 18.1536 9.91922 18.7398 10.63 18.7524H15.3679C16.0787 18.7398 16.6447 18.1536 16.6325 17.4429V10.2489" stroke="#E53E3E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<rect x="3.99609" y="3.99622" width="18.0075" height="18.0075" rx="4" stroke="#E53E3E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
name:"RemoveIcon"
}
</script>
