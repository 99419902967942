<template>
  <div
    class="col-span-12 flex items-center py-4 px-8 justify-between bg-black md:hidden block">
    <div>
      <ImageComponent :src="user.image_full_url" rounded="rounded-full" class="w-10 h-10" />
    </div>
    <router-link to="/">
      <Logo />
    </router-link>
    <div>
      <MenuIcon />
    </div>
  </div>
</template>

<script>
import ImageComponent from "@/components/shared/ImageComponent.vue";
import Logo from "@/components/svgs/Logo.vue";
import MenuIcon from "@/components/svgs/MenuIcon.vue";

export default {
  name: "MobileNav",

  components: {
    ImageComponent,
    Logo,
    MenuIcon,
  },

  computed: {
    user() {
      return this.$store.state.auth.user
    }
  }
};
</script>
