<template>
	<div :class="actionText" class="flex font-xs text-sm capitalize">
    <EnabledIcon 
      v-if="this.status === 'approved'"
      class="mr-2 w-5 h-5" 
    />
    <PendingIcon 
      v-else-if="this.status === 'pending' || this.status === 'on hold'"
      class="mr-2 w-5 h-5" 
    />
    <RejectedIcon 
      v-else-if="['rejected', 'expired'].includes(this.status)"
      class="mr-2 w-5 h-5" 
    />
    <span class="font-bold text-sm flex-shrink-0 capitalize">
      {{ status }}
    </span>
  </div>
</template>

<script>
  import PendingIcon from "@/components/svgs/PendingIcon.vue"
  import EnabledIcon from "@/components/svgs/EnabledIcon.vue"
  import RejectedIcon from "@/components/svgs/RejectedIcon.vue"

	export default {
		props: {
      status: String,
    },

    components: {
      EnabledIcon,
      PendingIcon,
      RejectedIcon,
    },

    computed: {
      actionText() {
        switch (this.status) {
          case 'approved': 
            return 'text-customGreen'
          case 'rejected':
          case 'expired':
            return 'text-customRed'
          case 'pending':
          case 'on hold':
          default: 
            return 'text-customYellow'
        }
      }
    }
	}
</script>