<template>
  <div class="p-8 md:p-12">
    <div class="flex flex-col mb-4 sm:mb-0 sm:flex-row lg:items-center justify-between lg:h-13">
      <h1 class=" font-black text-3xl mb-5 sm:mb-0">Organizations</h1>

      <div class="space-x-4 flex items-center">
        <Modal v-if="user.authorizedToCreateOrganizations">
          <button class="bg-green-500 rounded-lg text-white w-full hover:bg-green-600 text-xs w-24 px-5 flex items-center justify-center h-11 focus:outline-none focus:shadow-outline">
            <PlusIcon class="h-6" />
            <span class="ml-2 whitespace-nowrap">Add Organization</span>
          </button>

          <template v-slot:content="{ close }">
            <AddOrganizationCard @close="closeAddOrganizationModal(close)" />
          </template>
        </Modal>
        <SearchComponent class=""/>
      </div>
    </div>

    <template v-if="working">

    </template>

    <template v-else>
      <!-- title head -->
      <div
        class="hidden lg:grid bg-white  grid-cols-12 gap-4 py-4 px-10 rounded-xl mt-10 mb-8">
        <div class="col-span-6 space-x-16 text-sm text-gray-500 flex">
          <button class="flex items-center">
            <span class="mr-2">Name</span>
          </button>
        </div>
        <div class="col-span-6 space-x-16 text-sm text-gray-500 ">
          <span class="">President</span>
        </div>
      </div>
       <!-- Organization Card -->
      <OrganizationCard/>
    </template>
  </div>
</template>

<script>
import PlusIcon from "@/components/svgs/PlusIcon.vue";
import Modal from "@/components/shared/Modal.vue";
import AddOrganizationCard from "@/components/organization/AddOrganizationCard.vue";
import SearchComponent from "@/components/shared/SearchComponent.vue";
import OrganizationCard from "@/components/organization/OrganizationCard/index.vue";

export default {
  data() {
    return {
      working: true,
    }
  },  
  components: {
    SearchComponent,
    OrganizationCard,
    PlusIcon,
    Modal,
    AddOrganizationCard,
  },

  computed: {
    user() {
      return this.$store.state.auth.user
    },
  },

  methods: {
    fetchOrganizations() {
      this.working = true
      this.$store.dispatch('fetchOrganizations')
        .finally(() => this.working = false)
    },

    closeAddOrganizationModal(callback) {
      this.fetchOrganizations()
      callback.call()
    }
  },

  mounted() {
    this.fetchOrganizations()
  }
};
</script>
