<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.5664 7.44397L12.0104 15L8.2334 11.222" stroke="#00D900" stroke-width="1.53" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.0059 12C21.0059 16.971 16.9769 21 12.0059 21C7.03486 21 3.00586 16.971 3.00586 12C3.00586 7.029 7.03486 3 12.0059 3C13.4589 3 14.8269 3.353 16.0419 3.964" stroke="#00D900" stroke-width="1.53" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
name:'EnabledIcon'
}
</script>
