<template>
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.4182 10L15.7112 5.70701C16.1022 5.31601 16.1022 4.68401 15.7112 4.29301C15.3202 3.90201 14.6882 3.90201 14.2972 4.29301L10.0042 8.58601L5.71116 4.29301C5.32016 3.90201 4.68816 3.90201 4.29716 4.29301C3.90616 4.68401 3.90616 5.31601 4.29716 5.70701L8.59016 10L4.29716 14.293C3.90616 14.684 3.90616 15.316 4.29716 15.707C4.49216 15.902 4.74816 16 5.00416 16C5.26016 16 5.51616 15.902 5.71116 15.707L10.0042 11.414L14.2972 15.707C14.4922 15.902 14.7482 16 15.0042 16C15.2602 16 15.5162 15.902 15.7112 15.707C16.1022 15.316 16.1022 14.684 15.7112 14.293L11.4182 10Z" fill="#C53030"/>
</svg>

</template>

<script>
export default {
name:"RejectedIcon"
}
</script>

<style>

</style>