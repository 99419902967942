<template>
  <div class="px-8 py-4 md:px-12">
    <router-link :to="{name: 'events.index'}">
      <BackButton/>
    </router-link>    

    <template v-if="working">
      <div class="h-screen flex-1 flex items-center justify-center">
        <LoadingIcon class="h-2 text-customGreen" />
      </div>
    </template>
    <template v-else>
      <EventDetails :event="event" />
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import LoadingIcon from '@/components/svgs/LoadingIcon.vue';
import BackButton from '@/components/shared/BackButton.vue';
import EventDetails from '@/components/events/EventDetails/index.vue';

export default {
name:"EventDetail",
components: {
  LoadingIcon,
    BackButton,
    EventDetails
  },
  data() {
     return {
      working: true,
    };
  },

  computed: {
    event() {
      return this.$store.state.event
    }
  },

  methods: {
    ...mapActions({
      fetchEvent: 'fetchEvent',
    })
  },

  created() {
    this.fetchEvent(this.$route.params.eventId)
      .finally(() => this.working = false)
  }
}
</script>
